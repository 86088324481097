import { TokenType } from "./models/v1/token";
import api from "@/api/api-v1-config";
import { AxiosResponse, AxiosError } from 'axios';

export async function getToken(login: string, passwordHash: string): Promise<TokenType> {
    return await (await api.post("Auth", {login: login, password: passwordHash})).data;
}

export async function verifyToken(token: string): Promise<boolean> {
    await api.get("Auth/verify", { headers: { 'Authorization': 'Bearer ' + token }})
        .then((response: AxiosResponse) => {
            return true;
        })
        .catch((err: AxiosError) => {
            return false;
        });

        return true;
}