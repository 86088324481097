<template>
    <div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Login</label>
        <input type="text" class="form-control lg" v-model="login" />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="password" class="form-control lg" v-model="password" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-primary right" @click="verify">Confirm</button>
    </div>
  </div>
    </div>
</template>

<script>
import { getToken } from "@/api/auth-service";
import { state } from "@/store/app-store";
import { useStorage } from "vue3-storage";
import MD5 from "crypto-js/md5";

export default {
  data() {
    return {
      login: "",
      password: "",
    };
  },
  methods: {
    async verify() {
      state.isLoading = true;
      try {
        const storage = useStorage();
        const token = await getToken(this.login, MD5(this.password).toString());

        storage.setStorageSync("appToken", token);

        this.$router.push({ name: "admin.home" });
      } catch (e) {
        console.log("err:" + e);
      } finally {
        state.isLoading = false;
      }
    },
  },
};
</script>
